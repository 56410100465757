import React from 'react'
import { connect } from 'react-redux'
import { hideStoriesModal } from 'src/state/app'
import RichText from 'src/components/rich-text'
import 'src/styles/stories-modal.sass'

export class StoriesModal extends React.Component {
  render() {
    return (
      <div
        className={`
      stories-modal
      ${this.props.expanded ? 'expanded' : ''}
    `}
      >
        <div
          className="bg"
          onClick={() => this.props.dispatch(hideStoriesModal())}
        />
        <div className="modal">
          <div className="logo">
            <img
              src={this.props.story.logoDark.url}
              alt={this.props.story.logoDark.alt}
            />
          </div>
          <div className="type">{this.props.story.type}</div>
          <RichText className="body">{this.props.story.body}</RichText>
          <div
            className="close-btn"
            onClick={() => this.props.dispatch(hideStoriesModal())}
          >
            {this.props.closeModalText}
          </div>
        </div>
      </div>
    )
  }
}

export default connect((state) => ({
  closeModalText: state.app.closeModalText,
  expanded: state.app.storiesModalExpanded,
  story: state.app.currStory,
}))(StoriesModal)
