import React from 'react'
import { connect } from 'react-redux'
import { gotoSection } from 'src/state/app'
import 'src/styles/desktop-nav.sass'

export class DesktopNav extends React.Component {
  render() {
    return (
      <div
        className={`
        desktop-nav
        ${this.props.currSection.showNav ? 'visible' : ''}
      `}
      >
        {this.props.sections.map((section) => (
          <div
            key={section.id}
            className={`
            section-link
            ${section.id === this.props.currSection.id ? 'focused' : ''}
          `}
            onClick={() => this.props.dispatch(gotoSection(section.id))}
          >
            <div className="text">{section.navText}</div>
            <div className="hrule" />
          </div>
        ))}
      </div>
    )
  }
}

export default connect((state) => ({
  sections: state.app.sections,
  currSection: state.app.currSection,
}))(DesktopNav)
