import * as React from 'react'
import background from 'src/assets/hexes.svg'
import 'src/styles/floor.sass'
import { win } from 'src/utils'

class Floor extends React.Component {
  render() {
    let duration = 2
    let distance = 0.3
    let y = (this.props.index - 1) * (win ? win.innerHeight * distance : 0)
    const style = {
      transition: `transform ${duration}s`,
      transform: `translateY(${y}px)`,
      backgroundImage: `url('${background}')`,
    }
    return (
      <div className={`floor`}>
        <div className="pattern-container">
          <div className="pattern" style={style}>
            <div className="left-gradient-edge"></div>
            <div className="right-gradient-edge"></div>
          </div>
        </div>
        <div className="top-gradient"></div>
        <div className="fallback">
          <div className="top-gradient"></div>
          <img src={background} alt="Floor Fallback" />
        </div>
      </div>
    )
  }
}

export default Floor
