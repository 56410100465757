import React from 'react'
import { connect } from 'react-redux'
import menuIcon from 'src/assets/mobile-menu-icon.svg'
import closeIcon from 'src/assets/mobile-close-icon.svg'
import logo from 'src/assets/mobile-menu-logo.svg'
import { showMobileMenu, hideMobileMenu, gotoSection } from 'src/state/app'
import 'src/styles/mobile-nav.sass'

export class MobileNav extends React.Component {
  render() {
    return (
      <div
        className={`
        mobile-nav
        ${this.props.shouldShowMobileMenu ? 'show-menu' : ''}
        ${this.props.currSection.showNav ? 'visible' : ''}
      `}
      >
        <div
          className="menu-btn"
          onClick={() => this.props.dispatch(showMobileMenu())}
        >
          <img src={menuIcon} alt="Mobile Menu Icon" />
        </div>
        <div className="overlay">
          <div className="links">
            {this.props.sections.map((section) => (
              <div
                key={section.id}
                className={`
                section-link
                ${section.id === this.props.currSection.id ? 'focused' : ''}
              `}
                onClick={() => {
                  this.props.dispatch(hideMobileMenu())
                  this.props.dispatch(gotoSection(section.id))
                }}
              >
                <div className="text">{section.navText}</div>
              </div>
            ))}
          </div>
          <div
            className="close-btn"
            onClick={() => this.props.dispatch(hideMobileMenu())}
          >
            <img src={closeIcon} alt="Mobile Close Icon" />
          </div>
          <div className="logo">
            <img src={logo} alt="Mobile Logo" />
          </div>
        </div>
      </div>
    )
  }
}

export default connect((state) => ({
  shouldShowMobileMenu: state.app.shouldShowMobileMenu,
  sections: state.app.sections,
  currSection: state.app.currSection,
}))(MobileNav)
