import * as React from 'react'
import 'reset-css'
import 'src/styles/index.sass'
import chevron from 'src/assets/chevron.svg'
import Floor from 'src/components/floor'
import DesktopNav from 'src/components/desktop-nav'
import MobileNav from 'src/components/mobile-nav'
import StoriesModal from 'src/components/stories-modal'
import { connect } from 'react-redux'
import { gotoNextSection, gotoPreviousSection } from 'src/state/app'
import { win, forceLinksToOpenNewTab, browser } from 'src/utils'

class IndexPage extends React.Component {
  static defaultProps = {
    touchDelta: 75,
    showChevron: true,
    loadDelay: 200,
  }

  state = {
    scrollDelay: 2500,
    scrollTimeout: null,
    touchStartY: 0,
    loaded: false,
  }

  componentDidMount = () => {
    forceLinksToOpenNewTab()
    win && win.addEventListener('touchstart', this.onTouchStart)
    win && win.addEventListener('mousewheel', this.onMouseWheel)
    win && win.addEventListener('DOMMouseScroll', this.onMouseWheel)
    win && win.addEventListener('keydown', this.onKeyDown)
    setTimeout(() => this.setState({ loaded: true }), this.props.loadDelay)
  }

  onKeyDown = (event) => {
    if (['ArrowLeft', 'ArrowUp'].includes(event.key))
      this.props.dispatch(gotoPreviousSection())
    if (['ArrowRight', 'ArrowDown'].includes(event.key))
      this.props.dispatch(gotoNextSection())
  }

  onMouseWheel = (event) => {
    // console.log('onMouseWheel', event)
    if (this.state.scrollTimeout) {
      const scrollTimeout = setTimeout(
        () => this.setState({ scrollTimeout: null }),
        this.state.scrollDelay
      )
      this.setState({ scrollTimeout: scrollTimeout })
      clearTimeout(this.state.scrollTimeout)
      return
    }
    const delta = event.detail || event.wheelDelta
    const isDown = delta < 0

    if (this.props.currSection.index === 1 && !isDown) return
    if (this.props.currSection.index === this.props.sections.length && isDown)
      return

    const scrollTimeout = setTimeout(
      () => this.setState({ scrollTimeout: null }),
      this.state.scrollDelay
    )
    this.setState({ scrollTimeout: scrollTimeout })

    isDown
      ? this.props.dispatch(gotoNextSection())
      : this.props.dispatch(gotoPreviousSection())
  }

  onTouchStart = (event) => {
    // console.log('onTouchStart', event)
    win && win.removeEventListener('touchstart', this.onTouchStart)
    win && win.addEventListener('touchend', this.onTouchEnd)
    if (this.props.shouldShowMobileMenu) return
    const touchStartY = event.touches[0].screenY
    this.setState({ touchStartY: touchStartY })
  }

  onTouchEnd = (event) => {
    // console.log('onTouchEnd', event)
    win && win.addEventListener('touchstart', this.onTouchStart)
    win && win.removeEventListener('touchend', this.onTouchEnd)
    if (this.props.shouldShowMobileMenu) return
    const { touchStartY } = this.state
    const touchStopY = event.changedTouches[0].screenY
    const isSwipe = Math.abs(touchStartY - touchStopY) > this.props.touchDelta
    const isDown = touchStartY > touchStopY
    if (!isSwipe) return
    isDown
      ? this.props.dispatch(gotoNextSection())
      : this.props.dispatch(gotoPreviousSection())
  }

  render() {
    const { currSection, prevSection, placing, animating } = this.props
    const currIndex = currSection.index
    const prevIndex = prevSection ? prevSection.index : 0
    const { loaded } = this.state
    return (
      <main
        className={`
        ${loaded ? 'loaded' : ''}
        ${animating ? 'animating' : ''}
        ${browser.name}
      `}
      >
        <Floor index={currIndex} />
        <div className="sections">
          {this.props.sections.map((section) => {
            let scaleAmount = 2.5
            let scale = 0
            let opacity = 0
            let focused = false
            let style = {}
            let scaleTime = `0.5s`
            let fadeTime = `0.5s`
            let zIndex = 0
            let isForward = currIndex > prevIndex
            let blurAmount = browser.name === 'safari' ? 0 : `25px`

            if (placing) {
              if (section.index === currIndex) {
                opacity = 0
                scale = isForward ? 0 : scaleAmount
                zIndex = isForward ? 0 : 1
                focused = false
                blurAmount = 0
              }
              if (section.index === prevIndex) {
                opacity = 1
                scale = 1
                focused = false
                zIndex = isForward ? 1 : 0
                blurAmount = blurAmount
              }
            } else {
              if (section.index === currIndex) {
                opacity = 1
                scale = 1
                zIndex = isForward ? 0 : 1
                focused = true
                blurAmount = 0
              }
              if (section.index === prevIndex) {
                opacity = 0
                scale = isForward ? scaleAmount : 0
                zIndex = isForward ? 1 : 0
                focused = false
                blurAmount = blurAmount
              }
            }
            style.transition = placing
              ? `opacity ${fadeTime}, filter ${fadeTime}`
              : `opacity ${fadeTime}, filter ${fadeTime}, transform ${scaleTime}`
            style.transform = `scale(${scale})`
            style.filter = `blur(${blurAmount})`
            style.opacity = opacity
            style.zIndex = zIndex
            return (
              <section.Component
                key={section.id}
                focused={focused}
                section={section}
                style={style}
              />
            )
          })}
        </div>
        <div
          className={`
          chevron
          ${this.props.showChevron ? 'visible' : ''}
          ${this.props.animating ? 'animating' : ''}
        `}
        >
          <img src={chevron} alt="Chevron" />
        </div>
        <DesktopNav />
        <MobileNav />
        <StoriesModal />
      </main>
    )
  }
}

export default connect(
  (state) => ({
    currSection: state.app.currSection,
    prevSection: state.app.prevSection,
    placing: state.app.placing,
    animating: state.app.animating,
    sections: state.app.sections,
    shouldShowMobileMenu: state.app.shouldShowMobileMenu,
    showChevron: state.app.currSection.showChevron,
  }),
  null
)(IndexPage)
